@font-face {
    font-family: 'OmBotak';
    src: url('./fonts/OmBotak.woff') format('woff'), url('./fonts/OmBotak.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;

}

html, body {
  overflow-x: hidden;
  width: 100vw;
}

body {
  margin: 0;
  background-image: url(./images/sky.jpg);
  background-size: contain;
  background-repeat: repeat;
  font-family: -apple-system, BlinkMacSystemFont, 'OmBotak', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
