html{
  scroll-behavior: smooth;
}
.App {
  text-align: center;
}

.logo {
  height: 15vmin;

  pointer-events: none;
}

.eartido {
z-index: 5;
  height: 50vmin;

}

.container{
  justify-content: center;
  align-items: center;
  max-width: 80vw;
  text-align: center;
  margin: auto;
}

.card:hover{
  animation: card-wobble 0.25s linear;
}

.linkButton:hover{
  animation: card-wobble 0.25s linear;
}


.sectionTitles{
  margin-top: 10rem;
  margin-bottom: 3rem;
}

.downButton{
  animation: button-bounce 1s infinite linear;
}

@keyframes button-bounce {
  0%, 100%{
transform: translateY(0px);
  }
  50%{
    transform: translateY(-0.5rem);
  }
}

.faugbtn{
  width: 3rem;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.5);
  border-radius: 10%;
  border: solid;
  border-color: black;

}

.storeIcoBtn{
  width: 10%;
}
.storebtn{
  max-width:7rem;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.5);
  border-radius: 10%;
  border: solid;
  border-color: #4682B4;
}

.mccloud{
  
  height: 20%;
  width: 20%;
  position: absolute;
  top: 10rem;
  right: -20rem;
animation: mccloud-move 30s infinite linear ;
}
.bouncer{
  height: 100%;
  width: 100%;
  background-image: url(./images/McCloud.gif);
  background-repeat: no-repeat;
  background-size: contain;
 animation: mccloud-bounce 1.5s infinite ease;
}

@keyframes mccloud-move {
  0%{
transform: translateX(0px);
  }
  100%{
    transform: translateX(-140vw);
  }
}

@keyframes mccloud-bounce {
  0%, 100%{
    transform: translateY(0px);
  }
  50%{
    transform: translateY(0.25rem);
  }
}

@keyframes background-move {
  0%{
    transform: translateX(0px);
      }
      100%{
        transform: translateX(-140vw);
      }
}


@keyframes card-wobble {
0%, 50%, 100%{
  transform: translateX(0px);
}
25%{
  transform: translateX(0.1rem);
}
75%{
  transform: translateX(-0.1rem);
}
}

Footer{
  height: 5rem;
  background-image: url("./images/sky.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
  display: flex;
  justify-content: center;
  align-items: baseline;
  border-top: solid;
  border-color: white;
}

.footer{
  margin-top:1rem;
  height: 4rem;
  width: 100%;
  backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  color: white;
}


.App-container {
  
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


